import { ResultsValidation } from '@beathletics/api-interfaces';
import type { Results } from '@prisma/client';

export class LoadAllResults {
  static readonly type = '[Result API] Load all results';
}

export class SelectResult {
  static readonly type = '[Result API] Select result';
  constructor(public result: Results) {}
}

export class LoadAthleteOfTheResult {
  static readonly type = '[Result API] Load athlete of the result';
  constructor(
    public resultId: string,
    public athleteId: string,
  ) {}
}

export class LoadEventOfTheResult {
  static readonly type = '[Result API] Load event of the result';
  constructor(
    public resultId: string,
    public eventId: string,
  ) {}
}

export class UpdateResult {
  static readonly type = '[Result API] Update result';
  constructor(public result: Results) {}
}

export class UpdateArrayOfResults {
  static readonly type = '[Result API] Updates multiple results';
  constructor(public results: Results[]) {}
}

export class UpdateResultsValidation {
  static readonly type = '[Result API] Update results validation';
  constructor(public data: ResultsValidation[]) {}
}

export class DeleteResult {
  static readonly type = '[Result API] Delete result';
  constructor(public id: string) {}
}
