import {
  IDiscipline,
  INewTrials,
  IResult,
  ResultWithRelations,
  PrismaDisciplineGroup as DisciplineGroup,
} from '@beathletics/api-interfaces';
import type { NewTrials, Results } from '@prisma/client';
import { CODES_DISCIPLINES_WITH_TRIALS, CODES_HIGH_JUMPS } from './constants';

export function verifyResultPrecision(value: number | string | undefined, precision: number): string {
  if (!value) {
    return '';
  } else if (isNaN(+value)) {
    return '' + value;
  } else if (precision === 0) {
    return '' + value;
  } else {
    const valueArray = value?.toString()?.split('.');
    if (valueArray?.[1]) {
      if (valueArray[1].length === precision) return '' + value;
      else return formatResultPrecision(value.toString(), precision);
    } else {
      return formatResultPrecision(value.toString(), precision);
    }
  }
}

export function formatResultPrecision(value: string, precision: number) {
  let formattedValue = value;
  if (formattedValue !== '') {
    const valueArray = value?.toString()?.split('.');
    if (valueArray?.[1]) {
      for (let i = valueArray[1].length; i < precision; i++) {
        formattedValue += '0';
      }
    } else {
      formattedValue += '.';
      for (let i = 0; i < precision; i++) {
        formattedValue += '0';
      }
    }
  } else {
    return '';
  }
  return formattedValue;
}

export function doesResultHasDns(result: Results) {
  const res = result.result as any;
  if (res?.alternative_code === 'DNS') {
    return true;
  } else {
    if (Array.isArray(res?.results)) {
      for (const perf of res.results) {
        if (perf?.value === 'DNS') {
          return true;
        }
      }
    }
  }
  return false;
}

export function millisecondsToTimeString(value: number | string) {
  const ms = +value;
  const s = Math.floor(ms / 1000);
  const m = Math.floor(s / 60);
  const c = Math.floor((ms % 1000) / 10);
  const ss = s % 60;
  const mm = m !== 0 ? (m < 10 ? '0' + m : m) + "'" : '';
  return mm + (ss < 10 ? '0' + ss : ss) + '"' + (c < 10 ? '0' + c : c);
}

export function getHighJumpTrialsByHeightFromResult(
  data: Results,
): { height: string; trials: string; isBest: boolean }[] {
  const heights: string[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _res = data.result as any;
  for (const res of _res.results) {
    if (!heights.includes(res.metavalue)) heights.push(res.metavalue);
  }
  const heightsWithTrials = [] as any[];
  for (const h of heights) {
    const _h = {
      height: h,
      trials: '',
      isBest: false,
    };
    for (const res of _res.results) {
      if (res.metavalue === h) {
        _h.trials += res.value;
        if (res?.['@_bestresult'] === '1') {
          _h.isBest = true;
        }
      }
    }
    heightsWithTrials.push(_h);
  }
  return heightsWithTrials;
}

export function getIfIsResultWithTrials(result?: IResult | null, discipline?: IDiscipline | null) {
  if (result) {
    if (
      result.discipline?.disciplineGroup === DisciplineGroup.VerticalJumps ||
      discipline?.disciplineGroup === DisciplineGroup.VerticalJumps ||
      (result.discipline?.eventType?.national_code &&
        CODES_HIGH_JUMPS.includes(+result.discipline?.eventType?.national_code)) ||
      (discipline?.eventType?.national_code && CODES_HIGH_JUMPS.includes(+discipline?.eventType?.national_code))
    ) {
      return {
        isResultWithTrials: true,
        highJumpTrials: getHJTrialsByHeightFromResult(result),
        toDisplay: null,
      };
    } else {
      if (
        result.discipline?.disciplineGroup === DisciplineGroup.HorizontalJumps ||
        discipline?.disciplineGroup === DisciplineGroup.HorizontalJumps ||
        result.discipline?.disciplineGroup === DisciplineGroup.Throws ||
        discipline?.disciplineGroup === DisciplineGroup.Throws ||
        (result.discipline?.eventType?.national_code &&
          CODES_DISCIPLINES_WITH_TRIALS.includes(+result.discipline?.eventType?.national_code)) ||
        (discipline?.eventType?.national_code &&
          CODES_DISCIPLINES_WITH_TRIALS.includes(+discipline?.eventType?.national_code))
      ) {
        return {
          isResultWithTrials: true,
          highJumpTrials: null,
          toDisplay: null,
        };
      } else {
        return {
          isResultWithTrials: false,
          highJumpTrials: null,
          toDisplay: (result.newTrials ?? []).find((t) => t.best === true)?.rankingPerf + '',
        };
      }
    }
  } else {
    return {
      isResultWithTrials: false,
      highJumpTrials: null,
      toDisplay: null,
    };
  }
}

export function getHJTrialsByHeightFromResult(
  data: IResult,
  filterImpasse = true,
): { height: string; trials: string; isBest: boolean }[] {
  const heights: string[] = [];
  const trials = data.newTrials ?? [];

  for (const trial of trials) {
    const height = trial.rankingPerf
      ? !isNaN(+trial.rankingPerf)
        ? +trial.rankingPerf || '-'
        : trial.rankingPerf
      : '-';
    if (!heights.includes('' + height)) heights.push('' + height);
  }
  const heightsWithTrials = [] as {
    height: string;
    trials: string;
    isBest: boolean;
  }[];
  for (const h of heights) {
    const _h = {
      height: h,
      trials: '',
      isBest: false,
    };
    for (const trial of trials) {
      const height = trial.rankingPerf
        ? !isNaN(+trial.rankingPerf)
          ? +trial.rankingPerf || '-'
          : trial.rankingPerf
        : '-';
      if ('' + height === h) {
        _h.trials += trial?.trialData
          ? trial.trialData
          : data.competitionFeature
            ? data.competitionFeature
            : !isNaN(+height)
              ? '0'
              : 'NM';
        if (trial.best) {
          _h.isBest = true;
        }
      }
    }
    if (!filterImpasse || (filterImpasse && _h.trials !== '-')) {
      heightsWithTrials.push(_h);
    }
  }
  return heightsWithTrials;
}

export function formatTimeResult(time: string, precision?: number) {
  if (time !== undefined) {
    if (('' + time).includes(':')) {
      const arr = time?.split(':');
      const sec = arr?.[2]?.split('.');
      if (Array.isArray(arr) && Array.isArray(sec)) {
        if (precision && precision === 1) {
          return arr[0] + ':' + arr[1] + "'" + sec[0];
        } else {
          if (arr[1] === '00') return sec[0] + '"' + sec[1];
          else return arr[1] + "'" + sec[0] + '"' + sec[1];
        }
      }
    }
  }
  return time;
}

export function recalculateResultRanks(results: IResult[], lowerIsBest: boolean) {
  const resultsToSort = results.map((r) => {
    const perf = r.newTrials?.find((t) => (lowerIsBest ? t.homologationBest === true : t.best === true))?.rankingPerf;
    return {
      id: r.id,
      perf: perf && !r.competitionFeature ? +perf : undefined,
    };
  });
  resultsToSort.sort((a, b) => {
    if (a.perf && b.perf) {
      if (lowerIsBest) {
        return a.perf - b.perf;
      } else {
        return b.perf - a.perf;
      }
    } else if (a.perf) {
      return -1;
    } else if (b.perf) {
      return 1;
    } else {
      return 0;
    }
  });
  for (const [i, r] of resultsToSort.entries()) {
    const result = results.find((res) => res.id === r.id);
    if (result) {
      result.rank = r.perf && r.perf > 0 ? i + 1 : 0;
    }
  }
  return results;
}

export function getPerfAndWindOfBestResult(res: ResultWithRelations): {
  perf: string;
  wind: string;
} {
  // TODO : complete to deal with all kind of results
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const eventType = res?.eventType || (res?.resultEventType as any);
  if (eventType) {
    const code = eventType?.national_code || eventType?.nationalcode || eventType?.code;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _r = res?.result as any;
    if (_r && _r?.results && _r.results.length > 0) {
      if (_r.results.length > 1) {
        for (const [i, _res] of _r.results.entries()) {
          if (_res?.['@_bestresult'] === '1' || i === _r.results.length - 1) {
            if (
              CODES_HIGH_JUMPS.includes(+code) ||
              res.name.toLowerCase().includes('hauteur') ||
              res.name.toLowerCase().includes('perche')
            ) {
              const _perf: string = _r?.alternative_code || _res.metavalue;
              return {
                perf:
                  _perf !== ''
                    ? verifyResultPrecision(
                        _perf,
                        res?.eventType && res?.eventType?.precision ? ('' + res?.eventType?.precision).length - 1 : 2,
                      )
                    : 'DNS',
                wind: _res?.wind,
              };
            } else {
              return {
                perf: verifyResultPrecision(
                  _res.value,
                  res?.eventType && res?.eventType?.precision ? ('' + res?.eventType?.precision).length - 1 : 2,
                ),
                wind: _res?.wind,
              };
            }
          }
        }
      } else {
        const _res = _r.results[0];
        if (_res?.metavalue && _res?.metavalue !== '') {
          return {
            perf: verifyResultPrecision(
              _res.metavalue,
              res?.eventType && res?.eventType?.precision ? ('' + res?.eventType?.precision).length - 1 : 2,
            ),
            wind: _res?.wind,
          };
        } else {
          let _perf = _res?.time
            ? formatTimeResult(_res.time, res?.eventType?.precision)
            : _res?.value
              ? verifyResultPrecision(
                  _res.value,
                  res?.eventType && res?.eventType?.precision ? ('' + res?.eventType?.precision).length - 1 : 2,
                )
              : _res?.score_distance
                ? verifyResultPrecision(
                    _res.score_distance,
                    res?.eventType && res?.eventType?.precision ? ('' + res?.eventType?.precision).length - 1 : 2,
                  )
                : undefined;
          if (!_perf) {
            _perf = _r?.resultStatus?.dnf
              ? 'DNF'
              : _r?.resultStatus?.dns
                ? 'DNS'
                : _r?.resultStatus?.dsq
                  ? 'DSQ'
                  : _r?.resultStatus?.invalid
                    ? 'Invalide'
                    : 'NC';
          }
          return { perf: _perf, wind: _res?.wind };
        }
      }
    } else if (res?.combinedEvents) {
      // ? : adapt for combined Events ? (no case found yet)
      return { perf: '', wind: '' };
    }
  }
  return { perf: '', wind: '' };
}

export function getIfResultIsIndoorOrOutdoor(data: ResultWithRelations): 'INDOOR' | 'OUTDOOR' | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res = data?.result as any;
  if (res?.resultStatus?.indoor || res?.resultStatus?.indoor === false)
    return res.resultStatus.indoor ? 'INDOOR' : 'OUTDOOR';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataAlabus = data?.event?.alabusData as any;
  if (dataAlabus?.type === 'INDOOR' || dataAlabus?.type === 'OUTDOOR') return dataAlabus.type;
  if (dataAlabus?.type === 'WEGWEDSTRIJDEN' || dataAlabus?.type === 'VELDLOPEN') return null;
  const season = data?.event?.season?.toLowerCase();
  if (season && season.includes('indoor')) return 'INDOOR';
  if (season && season.includes('outdoor')) return 'OUTDOOR';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const eventType = data?.eventType || (data?.resultEventType as any);
  if (eventType?.venue === 'I') return 'INDOOR';
  if (eventType?.venue === 'O') return 'OUTDOOR';
  const code = eventType?.type_id || eventType?.code;
  if (code && +code > 1000 && +code < 2000) return 'INDOOR';
  if (code && +code < 1000) return 'OUTDOOR';
  return null;
}

export function handleTimeResult(value: string | number) {
  if (/[a-zA-Z]/.test('' + value)) {
    return value;
  }
  let returnValue = 0;
  let v = '' + value;
  if (v.includes("'")) {
    v = v.replace(/'/g, '.');
  }
  const testInt = +v;
  if (typeof value === 'number' || !isNaN(testInt)) {
    returnValue = +v * 1000;
  } else {
    if (v.includes('"')) {
      v = v.replace(/"/g, ':');
    }
    const val = v.split(':');
    for (const [i, v] of val.entries()) {
      if (i === val.length - 1) {
        returnValue += +v * 1000;
      } else if (i === val.length - 2) {
        returnValue += +v * 60 * 1000;
      } else if (i === val.length - 3) {
        returnValue += +v * 60 * 60 * 1000;
      } else {
        returnValue += +v * 24 * 60 * 60 * 1000;
      }
    }
  }
  return returnValue;
}

/**
 *
 * @depreacted
 * @param res
 * @returns
 */
export function findBestPerfFromOldData(res: IResult): NewTrials | undefined {
  // const results = (res.metadata as any)?.oldResult?.result?.results;
  // if (results && results?.length === 1 && results?.[0] !== null) {
  //   if ('time' in results[0]) {
  //     let perf = results[0].time;
  //     if (perf && typeof perf === 'string' && perf.includes(':')) {
  //       const p = perf.split(':');
  //       if (+p[p.length - 2] < 1) {
  //         perf = +p[p.length - 1] * 1000;
  //       } else {
  //         perf = +p[p.length - 2] * 60 + +p[p.length - 1];
  //       }
  //     }
  //     return {
  //       perf,
  //       perfType: EPerfType.TIME,
  //       best: true,
  //     };
  //   } else if ('score_distance' in results[0]) {
  //     return {
  //       perf: results[0].score_distance,
  //       perfType: EPerfType.UNKNOWN,
  //       best: true,
  //     };
  //   }
  // } else if (results && results?.length > 1) {
  //   for (const r of results) {
  //     if (r?.['@_bestresult'] === '1') {
  //       return { rankingPerf: r.value, perftype: EPerfType.UNKNOWN, best: true };
  //     }
  //   }
  // }
  throw new Error('Should not used old data anymore');
}

//Correct this function and use only this one
export function markCertifiablePerfFromTrialsArray(trials: NewTrials[]) {
  let homologationBest = {
    value: 0,
    wind: 0,
    index: -1,
  };

  for (const [i, trial] of trials.entries()) {
    trial.homologationValid = false;
    trial.homologationBest = false;
    const rankingPerf = trial.rankingPerf?.toNumber();
    if (rankingPerf) {
      const windValue = trial.windSpeed && !isNaN(+trial.windSpeed) ? +trial.windSpeed : 0;
      if (windValue <= 2 && rankingPerf > 0) {
        if (trial.trialData) {
          if (trial.trialData === '0') {
            trial.homologationValid = true;
          }
        } else {
          trial.homologationValid = true;
        }
      }
      if (trial.homologationValid && rankingPerf > homologationBest.value) {
        homologationBest = {
          value: rankingPerf,
          wind: windValue,
          index: i,
        };
      } else if (
        trial.homologationValid &&
        rankingPerf === homologationBest.value &&
        windValue < homologationBest.wind
      ) {
        homologationBest = {
          value: rankingPerf,
          wind: windValue,
          index: i,
        };
      }
    }
  }
  if (homologationBest.index > -1) {
    trials[homologationBest.index].homologationBest = true;
  }
  return trials;
}

export function markCertifiablePerfFromTrialsArrayFront(trials: INewTrials[]) {
  let homologationBest = {
    value: 0,
    wind: 0,
    index: -1,
  };

  for (const [i, trial] of trials.entries()) {
    trial.homologationValid = false;
    trial.homologationBest = false;
    const rankingPerf = trial.rankingPerf ? +trial.rankingPerf : undefined;
    if (rankingPerf) {
      const windValue = trial.windSpeed && !isNaN(+trial.windSpeed) ? +trial.windSpeed : 0;
      if (windValue <= 2 && rankingPerf > 0) {
        if (trial.trialData) {
          if (trial.trialData === '0') {
            trial.homologationValid = true;
          }
        } else {
          trial.homologationValid = true;
        }
      }
      if (trial.homologationValid && rankingPerf > homologationBest.value) {
        homologationBest = {
          value: rankingPerf,
          wind: windValue,
          index: i,
        };
      } else if (
        trial.homologationValid &&
        rankingPerf === homologationBest.value &&
        windValue < homologationBest.wind
      ) {
        homologationBest = {
          value: rankingPerf,
          wind: windValue,
          index: i,
        };
      }
    }
  }
  if (homologationBest.index > -1) {
    trials[homologationBest.index].homologationBest = true;
  }
  return trials;
}
