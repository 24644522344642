import { DisciplineGroups, PrismaDisciplineGroup as DisciplineGroup } from '@beathletics/api-interfaces';
import type { EventType } from '@prisma/client';
import { CODES_HIGH_JUMPS } from './constants';

export function getDisciplineGroupFromEventType(
  eventType?: Pick<EventType, 'discipline_group' | 'national_code'> | null,
): DisciplineGroup {
  if (eventType) {
    return eventType.discipline_group === DisciplineGroups.Lancer
      ? DisciplineGroup.Throws
      : eventType.discipline_group === DisciplineGroups.Multiple
        ? DisciplineGroup.CombinedDiscipline
        : eventType.discipline_group === DisciplineGroups.Demi_fond ||
            eventType.discipline_group === DisciplineGroups.Fond ||
            eventType.discipline_group === DisciplineGroups.Sprint ||
            eventType.discipline_group === DisciplineGroups.Haies ||
            eventType.discipline_group === DisciplineGroups.Relais
          ? DisciplineGroup.Race
          : CODES_HIGH_JUMPS.includes(+eventType.national_code)
            ? DisciplineGroup.VerticalJumps
            : DisciplineGroup.HorizontalJumps;
  } else {
    return DisciplineGroup.Unknown;
  }
}
