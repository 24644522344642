import {
  CalendarFilters,
  DashboardEventsQuery,
} from '@beathletics/api-interfaces';
import type { Event, Results } from '@prisma/client';

export class LoadAllEvents {
  static readonly type = '[Event API] Load all events';
}

export class SelectEvent {
  static readonly type = '[Event API] Select event';
  constructor(public eventNumber: string, public forceLoading = false) {}
}
export class LoadResultsOfTheEvent {
  static readonly type = '[Event API] Load all results of the event';
  constructor(public eventNumber: string) {}
}

export class UpdateEvent {
  static readonly type = '[Event API] Update event';
  constructor(public event: Event) {}
}

export class DeleteEvent {
  static readonly type = '[Event API] Delete event';
  constructor(public eventNumber: string) {}
}

export class ResultChanged {
  static readonly type = '[Event API] Result as changed';
  constructor(public result: Results) {}
}

export class LoadDashboardEvents {
  static readonly type = '[Event API] Get last events for the dashboard';
}

export class DeleteResultsOfTheEvent {
  static readonly type = '[Event API] Delete results of the event';
  constructor(public eventId: string) {}
}
